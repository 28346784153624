<template>
  <b-card-actions action-collapse title="Liste des contactes">
      <div class="custom-search d-flex justify-content-end">
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">Recherche</label>
            <b-form-input
              placeholder="Recherche"
              type="text"
              class="d-inline-block"
              @input="advanceSearch"
            />
          </div>
        </b-form-group>
      </div>

      <vue-good-table
        ref="table-category"
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
          //  mode: 'records'
        }"
        theme="my-theme"
      >
  <template
        slot="table-row"
        slot-scope="props"
      >
      <span v-if="props.column.label === 'Actions'" class="text-nowrap">
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>

              <b-dropdown-item @click="_deleteContact(props.row)">
                <feather-icon icon="TrashIcon" class="mr-50" />
                <span>Delete</span>
              </b-dropdown-item>
            </b-dropdown>
        </span>
        <!-- Column: Name -->
      <!--  <span
          v-if="props.column.label === 'Date'"
          class="text-nowrap"
        >
        <span>
           {{props.row.date | moment("YYYY-MM-DD")}}
        </span>
        </span>-->
  </template>
        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Afficher 1 à </span>
              <b-form-select
                v-model="pageLength"
                :options="['3', '5', '10']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> sur {{ props.total }} résultats </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card-actions>
</template>

<script>
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table';
import ContactsMixin from '@/mixins/contact.mixin';
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BDropdown,
  BDropdownItem,
  BAlert,
  BFormGroup,
  BListGroup,
  BListGroupItem,
  BFormInvalidFeedback,
  BInputGroup,
  BInputGroupPrepend,
  BCardText,
  VBTooltip,
  BPagination,
  BFormSelect,
  BForm,
  BTooltip,
} from "bootstrap-vue";
export default {
    
    mixins: [ContactsMixin],

    components: {
    BCardActions,
    VueGoodTable,
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BDropdown,
    BDropdownItem,
    BAlert,
    BFormGroup,
    BListGroup,
    BListGroupItem,
    BFormInvalidFeedback,
    BInputGroup,
    BInputGroupPrepend,
    BCardText,
    VBTooltip,
    BPagination,
    BFormSelect,
    BForm,
    BTooltip,
  },


    data(){
     return {
             pageLength: 5,
      searchTerm: "",
     columns: [
        {
          label: 'FullName',
          field: 'fullName',
        },
        {
          label: 'Email',
          field: 'email',
        },
        {
          label: 'Subject',
          field: 'subject'
        },
        {
          label: 'Content',
          field: 'message',
        } ,
        {
          label: 'Actions',
          field: 'action',
        } 
      ],
      rows: [
              
            ]
    };
  },

  mounted() {
      this.rows = this.contacts
  },
  methods: {
    // search category
    _deleteContact(item) {
          this.$swal({
        title: "Are you sure?",
        text: "You can't revert your action",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes Delete it!",
        cancelButtonText: "No, Keep it!",
        confirmButtonColor: "red",
        cancelButtonColor: "green",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("DeleteContact", item).then((data) => {
            if (data.data != "") {
              this.rows = [];

              this.rows = this.contacts;
            }
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Cancel Delete",
              icon: "TrashIcon",
              variant: "danger",
            },
          });
        }
      });
    },
    advanceSearch(val) {
      this.searchTerm = val;
    },
  },
  watch : {
      contacts : function() {
         this.rows = this.contacts
         console.log("test rows ",this.rows)

      }
  }
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>